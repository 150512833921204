<template>
  <div class="user">
    <!-- 添加用户 -->
    <el-dialog
      title="审核信息"
      :visible.sync="dialogFormVisible"
      @close="userClose(form)"
    >
      <el-form
        label-position="left"
        inline
        class="demo-table-expand"
        v-if="this.type == 1"
      >
        <el-form-item label="原定时间">
          <span>{{ form.add }}</span>
        </el-form-item>
        <el-form-item label="修改时间">
          <span>{{ form.del }}</span>
        </el-form-item>
        <el-form-item label="提交时间	">
          <span>{{ form.time }}</span>
        </el-form-item>
        <el-form-item label="申请事项	">
          <span>{{ form.name }}</span>
        </el-form-item>
        <el-form-item label="申请人姓名	">
          <span>{{ form.u_name }}</span>
        </el-form-item>
        <el-form-item label="任务名称	">
          <span>{{ form.p_name }}</span>
        </el-form-item>
        <el-form-item label="延期天数	">
          <span>{{ form.ceil }}</span>
        </el-form-item>
      </el-form>
      <el-input
        v-if="this.type == 2"
        type="textarea"
        :rows="2"
        placeholder="请输入内容"
        v-model="content"
        maxlength="255"
      >
      </el-input>
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="setConsent()" v-if="this.type == 1"
          >确 认</el-button
        >
        <el-button type="primary" @click="setConsent()" v-if="this.type == 2"
          >驳 回</el-button
        >
      </div>
    </el-dialog>
    <!-- 面包屑导航 -->
    <all-log :dialogLog="dialogLog" />
    <el-card>
      <!-- 头 -->
      <div class="menu-box">
        <h2>任务时间审核</h2>
        <div class="box-bd">
          <el-row :gutter="20">
            <el-col :span="4">
              <el-input placeholder="请输入内容" v-model="inputSearch">
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="search(inputSearch)"
                ></el-button>
              </el-input>
            </el-col>
            <el-col :span="4">
              <el-button type="warning" @click="dialogOpen">查看日志</el-button>
            </el-col>
          </el-row>
          <!-- 表格 -->
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
          >
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-form label-position="left" inline class="demo-table-expand">
                  <el-form-item label="原定时间">
                    <span>{{ props.row.add }}</span>
                  </el-form-item>
                  <el-form-item label="修改时间">
                    <span>{{ props.row.del }}</span>
                  </el-form-item>
                  <el-form-item label="提交时间	">
                    <span>{{ props.row.time }}</span>
                  </el-form-item>
                </el-form>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="申请事项"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="ceil"
              label="延期天数"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="u_name"
              label="申请人姓名"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="p_name"
              label="任务名称"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column label="任务状态" width="120">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.state == 1">待审核</el-tag>
                <el-tag v-if="scope.row.state == 2" type="success"
                  >审核通过</el-tag
                >
                <el-tag v-if="scope.row.state == 3" type="info"
                  >审核未通过</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column width="180" label="操作">
              <template v-if="scope.row.state === 1" slot-scope="scope">
                <el-button
                  size="mini"
                  type="success"
                  icon="el-icon-check"
                  @click="consent(scope.row)"
                  >通过</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-close"
                  @click="rejective(scope.row)"
                  >拒绝</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-pagination
            @current-change="pageChange"
            @size-change="sizeChange"
            :page-sizes="[7, 15, 20]"
            :pager-count="15"
            layout="total, sizes, prev, pager, next"
            :page-size="this.getInfo.page_num"
            background
            :total="exp.num"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
import { getCheckList, getConsent, getRefuse } from '@/api/taskall'

//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    Breadcrumb,
  },
  data() {
    //这里存放数据
    return {
      dialogLog: {
        state: false,
      },
      list: [],
      //总页数
      exp: {
        count: Number,
        num: 0,
      },
      getNav: [],
      //当前页码
      getInfo: {
        //搜索框
        path: null,
        info: '',
        page_code: 1,
        page_num: 7,
      },
      //单选列表
      radiolist: [],
      //上级列表
      options: [],
      content: '',
      inputSearch: '',
      //即将删除的信息
      userdelinfo: {},
      // 添加信息表单
      form: {
        name: '',
      },
      //表单名称长度
      formLabelWidth: '120px',
      //控制弹窗删除
      dialogVisible3: false,
      //控制弹窗--修改
      dialogFormVisible2: false,
      //控制弹窗--新增
      dialogFormVisible: false,
      //控制弹窗----权限
      dialogFormVisible4: false,
      //表格数据
      tableData: [],
      type: 1,
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    dialogOpen() {
      this.dialogLog.state = true
    },
    //打开权限
    async consent(info) {
      if (info.state != 1) {
        return
      }
      this.type = 1
      let pid = { pid: info.pid }
      console.log(pid)
      this.dialogFormVisible = true
      this.form = info
    },
    //打开权限
    async rejective(info) {
      if (info.state != 1) {
        return
      }
      this.type = 2
      this.dialogFormVisible = true
      this.form = info
    },
    //审核接口
    async setConsent() {
      let info
      let type = this.type
      if (type == 1) {
        info = await getConsent({ id: this.form.id })
      } else {
        info = await getRefuse({ id: this.form.id, content: this.content })
      }
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: 'success',
        })
        this.catalogue()
        this.dialogFormVisible = false
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
        return
      }
      this.catalogue()
    },
    //修改每页数量
    sizeChange(value) {
      console.log(value)
      this.getInfo.page_num = value
      this.catalogue()
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value
      this.catalogue()
    },
    //编辑
    handleEdit(row) {
      this.dialogFormVisible2 = true
      console.log(this.form)
      this.form = row
    },
    //同意
    async edit() {
      let info = await getEdit(this.form)
      if (info.data.code == 0) {
        this.$message({
          message: '修改成功',
          type: 'success',
        })
        this.dialogFormVisible2 = false
        this.catalogue()
      } else {
        this.$message({
          showClose: true,
          message: '修改失败',
          type: 'error',
        })
        return
      }
      this.catalogue()
    },
    //删除
    handleDelete(row, form) {
      this.dialogVisible3 = true
      this.userdelinfo = row
    },
    //搜索事件
    search(e) {
      console.log('搜索信息: ' + e)
    },
    //添加事件 退出弹窗
    userClose(e) {
      this.form = {
        name: '',
      }
      this.content = ''
      console.log('close')
    },
    //目录接口
    async catalogue() {
      let info = await getCheckList(this.getInfo)
      this.exp.count = info.data.exp.count
      this.exp.num = info.data.exp.num
      this.tableData = info.data.data
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.catalogue()
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
}
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.tab {
  width: 100%;
}
.tab .tab-tit {
  width: 100px;
  font-weight: bold;
}
.tab td {
  padding: 5px;
  vertical-align: baseline;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand >>> .el-form-item__label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand >>> .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
